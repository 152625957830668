import { Button, Col, DatePicker, Form, Icon, Input, message, Row, Select, Switch, Tooltip } from 'antd';
import { Component } from 'react';

import _ from "lodash";
import moment from "moment";
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';
import disablePastDates from '../../helpers/functions';

import 'antd/dist/antd.css';
import './Gathering.css';

const { Option } = Select;
const { WeekPicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};

class LocaleChurchLink extends Component {
  state = {
    isLocaleChurchInputted: false,
    isLinkInputted: false,
  }
  render() {
    const { localeChurches, index } = this.props;
    const { isLocaleChurchInputted, isLinkInputted } = this.state;

    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={24} lg={6}>
          <Form.Item
            validateStatus={isLinkInputted && !isLocaleChurchInputted ? "error" : ""}
            help={isLinkInputted && !isLocaleChurchInputted ? "Please select locale church" : ""}
          >
            <Select
              onChange={selected => {
                const isLocaleChurchInputted = !_.isEmpty(selected);
                this.setState({ isLocaleChurchInputted });
                this.props.handleLocaleChurchSelect(selected, index);
              }}
              style={{ width: "150px" }}
              allowClear={true}
            >
              {localeChurches && localeChurches.map(item => {
                return (
                  <Option key={item._id} value={item._id}>{item.name}</Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item
            validateStatus={isLocaleChurchInputted && !isLinkInputted ? "error" : ""}
            help={isLocaleChurchInputted && !isLinkInputted ? "Please input gathering link" : ""}
          >
            <Input
              onChange={e => {
                const isLinkInputted = !_.isEmpty(e.target.value);
                this.setState({ isLinkInputted });
                this.props.handleLinkInput(e.target.value, index);
              }}
              style={{ width: 400 }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    let searchParams = new URLSearchParams({
      q: value,
      roles: 'wrk',
    }).toString();
    amsAPI.getUrl(`/ams/members?${searchParams}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class CreateGathering extends Component {
  state = {
    localeChurches: [],
    gatheringTypes: [],
    gatherings: [],
    selectedLocaleChurches: {},
    members: [],
    assignedServants: [],
    name: '',
    type: '',
    startDateTime: null,
    endDateTime: null,
    channels: ['locale', 'mcgi.tv', 'zoom', 'youtube', 'facebook', 'instagram', 'satellite', 'others'],
    gatheringLink: '',
    isActive: false,
    isMain: false,
    isAutoStart: false,
    isDisplayLocaleLinks: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.getLocaleChurches()
      .then(res => {
        this.setState({
          localeChurches: res.locale_churches,
        })
      });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loadingTypes: false }))
      .catch(err => console.log(err));
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getLocaleChurches = async () => {
    const response = await amsAPI.getUrl('/ams/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleGatheringChange = value => {
    this.setState({ name: value })

    let defaultChannels = [];
    const zoomAndLineOnly = [ "nyr", "pmws", "pm", "ws", "pbb", "spbb", "ls", "tg", "spm", "nyr", "spbb1", "spbb2", "spbb3" ];
    if (zoomAndLineOnly.includes(value)) {
      defaultChannels = ['zoom', 'locale', 'others'];
    } else if (value === "sbk") {
      defaultChannels = [ 'zoom', 'mcgi.tv', 'instagram', 'youtube', 'satellite' ];
    } else if (value === "aods") {
      defaultChannels = [ "zoom" ];
    } else {
      defaultChannels = ['zoom', 'youtube', 'facebook', 'mcgi.tv', 'instagram', 'others'];
    }

    if (this.state.liveOrReplay === "replay" && constants.gatheringsWithReplay.includes(value)) {
      let query = { name: value , type: 'live' };
      this.callApi(`/ams/gatherings?${qs.stringify(query)}`)
        .then(res => this.setState({ gatherings: res.gatherings, loadingGatherings: false }))
        .catch(err => console.log(err));

    }

    this.setState({ channels: defaultChannels });
  }

  handleLiveOrReplayChange = value => {
    this.setState({ type: value, liveOrReplay: value, referenceGathering: null, weekNum: null })

    const { name } = this.state;
    if (value === "replay" && constants.gatheringsWithReplay.includes(name)) {
      let query = { name , type: 'live' };
      this.callApi(`/ams/gatherings?${qs.stringify(query)}`)
        .then(res => this.setState({ gatherings: res.gatherings, loadingGatherings: false }))
        .catch(err => console.log(err));
    }
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { referenceGathering, refWeekNum, type } = this.state;

    let modWeekNum = null;
    if (type === "live" && this.state.startDateTime) {
      modWeekNum = this.state.startDateTime;
    } else if (type === "replay" && constants.gatheringsWithReplay.includes(this.state.name)) {
      modWeekNum = refWeekNum ? refWeekNum : null;
    }
    const weekNum = moment.utc(modWeekNum).format('YYYYww');

    await amsAPI.fetchUrl(`/ams/gatherings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        type: this.state.type,
        liveOrReplay: this.state.liveOrReplay,
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        channels: this.state.channels,
        meetingId: this.state.meetingId,
        passcode: this.state.passcode,
        gatheringLink: this.state.gatheringLink,
        active: this.state.isActive,
        autoStart: this.state.isAutoStart,
        isMain: this.state.isMain,
        weekNum: weekNum,
        weekNumInt: parseInt(weekNum),
        scope: this.props.scope,
        selectedLocaleChurches: this.state.selectedLocaleChurches,
        userMemberId: this.props.userMemberId,
        assignedServants: this.state.assignedServants,
        referenceGathering: type === "replay" ? referenceGathering : null,
      }),
    })
    .then(res => {
      if (res.status === 200) {
        message.success("Gathering successfully created.");
        this.props.history.push('/gatherings');
      } else if (res.status === 422) {
        message.error("Gathering already registered.");
      }
    })
    .catch(err => {
      message.error("Error in registering gathering.");
      console.log(err);
    });

  };

  handleLocaleChurchSelect = (selection, index) => {
    const { selectedLocaleChurches } = this.state;
    if (_.isEmpty(selectedLocaleChurches[index])) {
      selectedLocaleChurches[index] = {};
    }
    selectedLocaleChurches[index] = {
      link: selectedLocaleChurches[index].link,
      localeChurch: selection,
    };
    this.setState({ selectedLocaleChurches });
  };

  handleLinkInput = (value, index) => {
    const { selectedLocaleChurches } = this.state;
    if (_.isEmpty(selectedLocaleChurches[index])) {
      selectedLocaleChurches[index] = {};
    }
    selectedLocaleChurches[index] = {
      localeChurch: selectedLocaleChurches[index].localeChurch,
      link: value,
    };
    this.setState({ selectedLocaleChurches });
  };

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = assignedServants => {
    this.setState({ assignedServants });
  };

  render() {
    const {
      name, type, startDateTime, channels,
      localeChurches, selectedLocaleChurches,
      isDisplayLocaleLinks,
      gatheringTypes, members, assignedServants,
      gatherings, liveOrReplay, referenceGathering, weekNum, refWeekNum,
    } = this.state
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    })

    const liveOrReplayOptions = Object.entries(constants.gatheringTypes);
    const channelEntries = Object.entries(constants.channels);
    let disableSubmit = !name || !type || _.isEmpty(channels) || !startDateTime || _.isEmpty(assignedServants || !weekNum);

    let modWeekNum = null;
    if (liveOrReplay === "replay" && constants.gatheringsWithReplay.includes(this.state.name)) {
      modWeekNum = refWeekNum ? moment.utc(refWeekNum) : null;
      disableSubmit = disableSubmit || !modWeekNum;
    } else {
      modWeekNum = moment(startDateTime);
    }

    const filteredlocaleChurches = localeChurches.filter(item => {
      let hasMatch = false;
      const keys = Object.keys(selectedLocaleChurches);
      for (let i = 0; i < keys.length; i++) {
        if (selectedLocaleChurches[keys[i]].localeChurch === item._id) {
          hasMatch = true;
          break;
        }
      }
      return !hasMatch;
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item
                  label="Gathering"
                  validateStatus={ !name ? "error" : ""}
                  help={ !name ? "Please select a gathering" : ""}
                >
                  <Tooltip
                    title={<span>Please double-check this. It won't be editable once start date and time has passed.</span>}
                    placement="right"
                    zIndex={1}
                    visible={true}
                  >
                    <Select
                      showSearch
                      placeholder="Select a gathering"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      onChange={this.handleGatheringChange}
                    >
                      {gatheringEntries.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Tooltip>
                </Form.Item>
                <Form.Item
                  label="Type"
                  validateStatus={_.isEmpty(type) ? "error" : ""}
                  help={_.isEmpty(type) ? "Please select if live or replay" : ""}
                >
                  <Tooltip
                    title={<span>Please double-check this. It won't be editable once start date and time has passed.</span>}
                    placement="right"
                    zIndex={1}
                    visible={true}
                  >
                    <Select
                      showSearch
                      placeholder="Select a gathering type"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      onChange={this.handleLiveOrReplayChange}
                    >
                      {liveOrReplayOptions.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Tooltip>
                </Form.Item>
                {this.state.type === "replay" && constants.gatheringsWithReplay.includes(this.state.name) ?
                  <Form.Item
                    label="Reference Gathering"
                    validateStatus={ !referenceGathering ? "error" : ""}
                    help={ !referenceGathering ? "Please select a reference gathering" : ""}
                  >
                    <Select
                      showSearch allowClear
                      placeholder="Select a gathering"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      onChange={value => {
                        this.setState({ referenceGathering: value })
                        if (value) {
                          this.getGathering(value)
                          .then(async res => {
                            const { data } = res;
                            this.setState({ refWeekNum: data.startDateTime });
                          })
                        }
                      }}
                    >
                      {gatherings.map(item =>
                        <Option key={item._id} value={item._id}>
                          {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {gatheringTypesMap[item.name]} ({item.type})
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                  :
                  null
                }
                <Form.Item
                  placeholder={"Input member name or id"}
                  label="Assiged Servant"
                  validateStatus={_.isEmpty(assignedServants) ? "error" : ""}
                  help={_.isEmpty(assignedServants) ? "Please input assigned servant" : ""}
                >
                  <Select
                    showSearch
                    placeholder={"Input member name or id"}
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    optionFilterProp="value"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    value={assignedServants}
                    allowClear={true}
                    mode="multiple"
                  >
                    {members.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {`${item.name}`}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Scope">
                  <Select
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    value={this.props.scope.charAt(0).toUpperCase() + this.props.scope.substring(1)}
                    disabled={true}
                  >
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Start Time"
                  validateStatus={ !startDateTime ? "error" : ""}
                  help={ !startDateTime ? "Please input start date and time" : ""}
                >
                  <Tooltip
                    title={<span>Please double-check this. It won't be editable once start date and time has passed.</span>}
                    placement="right"
                    zIndex={1}
                    visible={true}
                  >
                    <DatePicker
                      showToday
                      format="YYYY-MM-DD h:mm a"
                      showTime={{ use12Hours: true, format: "h:mm a" }}
                      placeholder="Starting date and time"
                      value={this.state.startDateTime}
                      onChange={(value) => {
                        this.setState({ startDateTime: moment(value).startOf('minute'), weekNum: value })
                      }}
                      disabledDate={disablePastDates}
                    />
                  </Tooltip>
                </Form.Item>
                <Form.Item
                  label= {
                    <span>
                      Is Auto-Open&nbsp;
                      <Tooltip
                        title="Gathering will auto-start or open 1 hour before the specified start date and time"
                        placement="right"
                        zIndex={1}
                      >
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isAutoStart: checked })}
                  />
                </Form.Item>
                <Form.Item label="Close Time">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="Closing date and time"
                    value={this.state.endDateTime}
                    onChange={(value) => this.setState({ endDateTime: moment(value).endOf('minute') })}
                    disabledDate={disablePastDates}
                  />
                </Form.Item>
                <Form.Item
                  label="Channels"
                  validateStatus={_.isEmpty(channels) ? "error" : ""}
                  help={_.isEmpty(channels) ? "Please select channels" : ""}
                >
                  <Select
                    placeholder="Please select channels"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={(value) => this.setState({ channels: value })}
                    allowClear={true}
                    mode={"multiple"}
                    value={channels}
                  >
                    {channelEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Meeting ID"
                >
                  <Input
                    onChange={(e) => this.setState({ meetingId: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Passcode"
                >
                  <Input
                    onChange={(e) => this.setState({ passcode: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Gathering Link"
                >
                  <Input
                    onChange={(e) => this.setState({ gatheringLink: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label="Is active?" colon={false} >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isActive: checked })}
                  />
                </Form.Item>
                <Form.Item label="Is Main Gathering?" colon={false} >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isMain: checked })}
                  />
                </Form.Item>
                <Form.Item
                  label="Week Num"
                >
                  <WeekPicker
                    format="YYYYww"
                    value={modWeekNum}
                    allowClear={false}
                    open={false}
                  />
                </Form.Item>
                <Form.Item label="Input Locale Links?" colon={false} >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isDisplayLocaleLinks: checked })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {isDisplayLocaleLinks &&
            <div>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>Locale Gathering Links</h3>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form layout="inline">
                  {_.range(this.state.localeChurches.length).map(i => {
                    return (
                      <LocaleChurchLink
                        localeChurches={filteredlocaleChurches}
                        handleLocaleChurchSelect={this.handleLocaleChurchSelect}
                        handleLinkInput={this.handleLinkInput}
                        index={i}
                        key={i}
                      />
                    )
                  })}
                </Form>
              </Col>
            </Row>
            </div>
          }
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    block
                    onClick={this.handleSubmit}
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmit}
                  >Register</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateGathering;
