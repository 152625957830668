export const gatherings = {
  'mi': 'Mass Indoctrination',
  'wbs': 'Worldwide Bible Study',
  'wbe': 'Worldwide Bible Exposition',
  'gph': "Global Prayer for Humanity",
  'sbk': "Serbisyong Kapatiran",
  'skp': "Serbisyong Kapatiran (Afternoon Edition)",
  'pm': 'Prayer Meeting',
  'ws': 'Worship Service',
  'tg': 'Regular Thanksgiving',
  'spm': 'Special Prayer Meeting',
  'spbb': 'Special Thanksgiving',
  'kgm': "KAPI General Meeting",
  'pmws': 'Combined PM and WS',
  'nyr': 'Christian New Year',
  'ls': "Lord's Supper",
  'nbo': "Newly Baptized Orientation",
  'aods': "AOD Sharing",
  'aodm': "AOD Meeting",
  'sbs': "Serbisyong Kapatiran (Special Edition)",
  'sms': "Social Media Summit",
};

export const gatheringsWithReplay = [
  //'pm', 'ws', 'pmws', 'tg', 'spbb1d', 'spbb1', 'spbb2', 'spbb3', 'ls', 'nyr',
  'pm', 'wspm', 'ws', 'tg', 'spbb1', 'spbb2', 'spbb3', 'spbb1d', 'nyr', 'pmws', 'ls',
];

export const activities = {
  'sto': 'STO Meeting',
  'gen': 'General Meeting',
  'grd': 'Graduation',
  'prc': 'Practice',
  '247': '24x7 Destino',
  'ara': 'Aralan',
  'vmf': 'Virtual Music Festival',
};

export const gatheringTypes = {
  'replay': 'Replay',
  'live': 'Live',
};

export const channels = {
  'zoom': 'Zoom',
  'locale': 'Locale',
  'mcgi.tv': 'MCGI.tv',
  'line': 'Line',
  'youtube': 'YouTube',
  'facebook': 'Facebook',
  'instagram': 'Instagram',
  'others': 'Other'
};

export const remittanceMethods = {
  'mail': 'Registered Mail',
  'bank': 'Bank Transfer',
};

export const remittanceStatuses = {
  'remitted': 'Remitted',
  'received': 'Received (Closed)',
  'duplicate': 'Duplicate',
  'needs-action': 'Needs Action',
  'for-correction': 'For Correction',
  'pending': 'Pending',
  'cancelled': 'Cancelled',
};

export const meetingIdsFilter = [
  {
    label: '777 333 6244',
    value: '7773336244',
  },
  {
    label: '701 705 0708',
    value: '7017050708',
  },
  {
    label: '333 777 0407',
    value: '3337770407',
  },
  {
    label: '701 705 7088',
    value: '7017057088',
  },
];

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

export const dataSource = [
  {
    key: '1',
    name: 'Juan Dela Cruz',
    localeChurch: 'Kawasaki',
    churchId: '00100020',
  },
];

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Church Id',
    dataIndex: 'churchId',
    key: 'churchId',
  },
  {
    title: 'Locale',
    dataIndex: 'localeChurch',
    key: 'localeChurch',
  },
];

export const lineLink = [
  {
    key: '1',
    churchId: '00100020',
    lineLink: 'https://line.me/ti/p/gSOCj2tfjw',
  },
];

export const lineLinkColumns = [
  {
    title: 'Church Id',
    dataIndex: 'churchId',
    key: 'churchId',
  },
  {
    title: 'Line Link',
    dataIndex: 'lineLink',
    key: 'lineLink',
  },
];

export const localeChurchSource = [
  {
    key: '1',
    division: 'Asia Oceania Division',
    district: 'AOD District 1',
    country: 'Japan',
    localeChurch: 'Kawasaki',
  },
  {
    key: '2',
    division: 'Middle East Division',
    district: '',
    country: 'United Arab Emirates',
    localeChurch: 'Dubai',
  },
];

export const localeChurchColumns = [
  {
    title: 'Division',
    dataIndex: 'division',
    key: 'division',
  },
  {
    title: 'District',
    dataIndex: 'district',
    key: 'district',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Locale',
    dataIndex: 'localeChurch',
    key: 'localeChurch',
  },
];

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
]

export const nationalities = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Anguillan',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bermudian',
  'Bhutanese',
  'Bolivian',
  'Botswanan',
  'Brazilian',
  'British',
  'British Virgin Islander',
  'Bruneian',
  'Bulgarian',
  'Burkinan',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Cayman Islander',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Citizen of Antigua and Barbuda',
  'Citizen of Bosnia and Herzegovina',
  'Citizen of Guinea-Bissau',
  'Citizen of Kiribati',
  'Citizen of Seychelles',
  'Citizen of the Dominican Republic',
  'Citizen of Vanuatu ',
  'Colombian',
  'Comoran',
  'Congolese (Congo)',
  'Congolese (DRC)',
  'Cook Islander',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cymraes',
  'Cymro',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirati',
  'English',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Faroese',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Gibraltarian',
  'Greek',
  'Greenlandic',
  'Grenadian',
  'Guamanian',
  'Guatemalan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hong Konger',
  'Hungarian',
  'Icelandic',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakh',
  'Kenyan',
  'Kittitian',
  'Kosovan',
  'Kuwaiti',
  'Kyrgyz',
  'Lao',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtenstein citizen',
  'Lithuanian',
  'Luxembourger',
  'Macanese',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian',
  'Maltese',
  'Marshallese',
  'Martiniquais',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monegasque',
  'Mongolian',
  'Montenegrin',
  'Montserratian',
  'Moroccan',
  'Mosotho',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'Niuean',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Pitcairn Islander',
  'Polish',
  'Portuguese',
  'Prydeinig',
  'Puerto Rican',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Salvadorean',
  'Sammarinese',
  'Samoan',
  'Sao Tomean',
  'Saudi Arabian',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Sierra Leonean',
  'Singaporean',
  'Slovak',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'South Sudanese',
  'Spanish',
  'Sri Lankan',
  'St Helenian',
  'St Lucian',
  'Stateless',
  'Sudanese',
  'Surinamese',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian',
  'Tristanian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Turks and Caicos Islander',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbek',
  'Vatican citizen',
  'Venezuelan',
  'Vietnamese',
  'Vincentian',
  'Wallisian',
  'Welsh',
  'Yemeni',
  'Zambian',
  'Zimbabwean',
];